import React, { useState, useEffect } from "react";
import QueryCollegeBrochure from "./college_brouchers/QueryCollegeBrochure";
import QueryCollegeMou from "./college_mou/QueryCollegeMou";
import { useNavigate } from "react-router-dom";

const Home = ({ user }) => {
  const [database, setDatabase] = useState("");
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const user = sessionStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      setUserRole(parsedUser);
      setIsAdmin(parsedUser.is_admin);
    }
  }, []);

  const gotoAddPDF = () => {
    navigate("/add-pdf");
  };

  const mvpDashboard = () => {
    navigate("/DashboardMvp");
  };

  const ragDashboard = () => {
    navigate("/rag-dashboard");
  };

  const toToPromptCRUD = () => {
    navigate("/prompt-crud");
  };

  if (!isAdmin) {
    return (
      <>
        <div className="container">
          <div className="row mt-3">
            <h1 className="h2">
              <i
                class="fa-solid fa-triangle-exclamation me-3"
                style={{
                  color: "#D2042D",
                }}
              ></i>
              <strong
                style={{
                  color: "#D2042D",
                }}
              >
                Not Authorized!
              </strong>
            </h1>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="container">
        {database === "" && (
          <div className="row mt-3">
            <div className="col-md-12">
              {/* <button
                className="btn btn-success"
                onClick={() => setDatabase("college")}
              >
                College Brochure
              </button> */}

              <button
                className="btn btn-success ms-3"
                onClick={() => setDatabase("finance")}
              >
                College MOU's
              </button>
{/* 
              <button className="btn btn-success ms-3" onClick={gotoAddPDF}>
                Add pdf
              </button>

              <button className="btn btn-success ms-3" onClick={mvpDashboard}>
                Dashboard (MVP)
              </button> */}

              <button className="btn btn-success ms-3" onClick={ragDashboard}>
                RAG Dashboard
              </button>

              <button className="btn btn-success ms-3" onClick={toToPromptCRUD}>
                Prompt CRUD
              </button>

              {/* <button
                className="btn btn-success ms-3"
                onClick={() => {
                  navigate("/botpress-saarthi");
                }}
              >
                BotPress GPT
              </button> */}

              {/* <button
                className="btn btn-success ms-3"
                onClick={() => {
                  navigate("/saarthi-4o-mini");
                }}
              >
                Saarthi GPT 4o-mini
              </button> */}

              <button
                className="btn btn-success ms-3"
                onClick={() => {
                  navigate("/update-institute");
                }}
              >
                Update Institute Status
              </button>

              <button
                onClick={() => {
                  navigate("/reset-institute");
                }}
                className="btn btn-success ms-3"
              >
                Reset Institutes
              </button>
            </div>
          </div>
        )}

        {database === "" && userRole.role === "cld_brochure" && (
          <div className="row mt-3">
            <div className="col-md-6">
              <button
                className="btn btn-success"
                onClick={() => setDatabase("college")}
              >
                College Brochure
              </button>
            </div>
          </div>
        )}

        {database === "" && userRole.role === "cld_mou" && (
          <div className="row mt-3">
            <div className="col-md-6">
              <button
                className="btn btn-success ms-3"
                onClick={() => setDatabase("finance")}
              >
                College MOU's
              </button>
            </div>
          </div>
        )}

        {database === "" && userRole.role === "cld_mvp" && (
          <div className="row mt-3">
            <div className="col-md-6">
              <button className="btn btn-success ms-3" onClick={mvpDashboard}>
                Dashboard (MVP)
              </button>
            </div>
          </div>
        )}

        {database === "college" && (
          <QueryCollegeBrochure database={database} setDatabase={setDatabase} />
        )}
        {database === "finance" && (
          <QueryCollegeMou database={database} setDatabase={setDatabase} />
        )}
      </div>
    </div>
  );
};

export default Home;
