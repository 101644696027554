import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const InstituteReset = ({ user }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    const user_details = JSON.parse(user);
    setIsAdmin(user_details.is_admin);
  }, []);

  const handleSubmit = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CENTRAL_REPO_SERVER_URL}/reset-institutes`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setFile("");
      console.log(response.data);
      showSuccessMessage("Success");
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      showErrorMessage(`Error: ${error}`);
    }
  };

  const showSuccessMessage = (message) => {
    toast.success(message, {
      position: "top-right",
      theme: "dark",
      autoClose: 2000,
      hideProgressBar: true,
      icon: false,
    });
  };

  const showErrorMessage = (message) => {
    toast.error(message, {
      position: "top-right",
      theme: "dark",
      autoClose: 2000,
      hideProgressBar: true,
      icon: false,
    });
  };

  if (!isAdmin) {
    return (
      <>
        <div className="container">
          <div className="row mt-3">
            <h1 className="h2">
              <i
                class="fa-solid fa-triangle-exclamation me-3"
                style={{
                  color: "#D2042D",
                }}
              ></i>
              <strong
                style={{
                  color: "#D2042D",
                }}
              >
                Not Authorized!
              </strong>
            </h1>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header bg-dark text-white">
                Reset Institutes
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label className="mb-2">Enter Sheet:</label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".csv"
                    onChange={handleFileChange}
                  />
                </div>
                <button className="btn btn-danger mt-3" onClick={handleSubmit}>
                  Reset Institute
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstituteReset;
